@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap');


appbody {
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ececec;
  display: grid;
  grid-template-columns: 25fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 5fr;
  grid-template-rows: 1vw 175px 175px 175px 175px 30px;
  grid-gap: 20px;
}

#root {
  height: 100%;
}

Link{
  text-decoration: none;
}
nav {

  background-color: #bda5d0;
  grid-column: 1;
  grid-row: 1 / 7;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.portfolio-title {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Anonymous Pro", monospace;
  font-size: 3em;
  color: white;
}

.portfolio-text {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Anonymous Pro", monospace;
  font-size: 1em;
  color: white;

}

/* far left */
.project-iterative {

  grid-column:  2 / 5;
  grid-row: 2 / 4;
  border-radius: 20px;
  text-align: center;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Anonymous Pro", monospace;
  background-color: #fee7a3 ;
  color: #5cc097;
  border-style: solid;
  border-color: #fee7a3;
  border-width: 8px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

/* middle up */

.project-responsive{
  grid-column: 6 / 9;
  grid-row: 3 / 5;
  border-radius: 20px;
  text-align: center;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: #a2dbd0;
  background-color: #a2dbd0;
  border-width: 8px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  color: #F68E8E;
}

/* far right */

.project-library{
  background-color: #eeadc4;
  grid-column: 10 / 13;
  grid-row: 2 / 4;
  border-radius: 20px;
  text-align: center;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e7e8e9;
  border-style: solid;
  border-color: #eeadc4;
  border-width: 8px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.title-library {
  font-family: "Anonymous Pro", monospace;
  font-size: 4vw;
  margin-bottom: 10px;
}

.title-personas {
  font-family: "Anonymous Pro", monospace;
  font-size: 3vw;
  margin-bottom: 10px;
}

.project-personas{
  background-color: #bda5d0;

  grid-column: 9 / 12;
  grid-row: 4 / 6;
  border-radius: 20px;
  text-align: center;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:white;
  border-style: solid;
  border-color: #bda5d0;;
  border-width: 8px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.colorblock-one{
  background-color: #eeadc4;
  grid-column: 3 / 6;
  grid-row: 4;
  border-radius: 20px;
}

.colorblock-two{
  background-color: #bda5d0;
  grid-column: 5 / 8;
  grid-row: 5;
  border-radius: 20px;
}

.colorblock-three{
  background-color: #fee7a3;
  grid-column: 7 / 10;
  grid-row: 2 ;
  border-radius: 20px;
}

footer {
  background-color: #a2dbd0;
  grid-column: 1 / 14;
  grid-row: 6;
}

button {
  border-style: none;
  border-radius: 10px ;
  background-color: #fee7a3;
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-family: "Anonymous Pro", monospace;
  font-size: 1.4em;
  width: 166px;
  height: 46px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}


